import React from 'react';
import NavBar from '../NavBar';
import Footer from '../Footer';

const Layout = props => {
  return (
    <React.Fragment>
      <NavBar nav={props.nav} banner={props.banner} />
      {props.children}
      <Footer policyContent={props.policyContent} />
    </React.Fragment>
  );
};

export default Layout;
