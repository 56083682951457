import React from 'react';

export default () => {
    return <div className="lds-roller-container">
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <h3 className="fs-h3">Loading</h3>
    </div>
}