import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FallBackSrc from "../../assets/placeholder.png";

const Image = ({ src, fallbackSrc, isExternal, useImgTag, ...restProps }) => {
  const [imageSource, setImageSource] = useState(src);
  const [error, setError] = useState(false);

  const onError = () => {
    if (!error) {
      setImageSource(fallbackSrc);
      setError(true);
    }
  };

  const render = s => {
    
    if (!useImgTag) {
      var bgImg = {
        background: `url(${s}) no-repeat center center scroll`,
        backgroundSize: 'cover'
      };
      return <div style={bgImg} {...restProps}></div>;
    } else {
      return <img src={s} onError={onError} {...restProps} />;
    }
  };
  if (isExternal) {
    let domain = !error ? process.env.REACT_APP_API_DOMAIN : '';
    //return render(imageSource);
    return render(`${domain}${imageSource}`);
  }
  return render(imageSource);
};

Image.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  isExternal: PropTypes.bool,
  useImgTag: PropTypes.bool,
  optimize: PropTypes.bool
};
Image.defaultProps = {
  fallbackSrc: FallBackSrc,
  isExternal: true,
  useImgTag: true,
};

export default Image;
