import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Slide from '@material-ui/core/Slide'
import { Link, withRouter } from 'react-router-dom'
import { Container } from '@material-ui/core'
import Image from '../Image'
import './style.css'

function HideOnScroll(props) {
  const { children } = props
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    const handleScrollClose = () => {
      setIsOpen(false)
    }
    document.addEventListener('scroll', handleScrollClose)

    return () => {
      document.removeEventListener('scroll', handleScrollClose)
    }
  }, [])

  return (
    <React.Fragment>
      <Slide direction="down" in={!isOpen}>
        <div className="desktop-navbar-hidden-replacement hide-on-mobile">
          <span
            hidden={isOpen}
            className="desktop-navbar-hamburger"
            onMouseOver={() => {
              setIsOpen(true)
            }}
          >
            <span className="fa fa-bars fa-3x"></span>
          </span>
          {/* <a
            className="btn btn-black"
            href="https://reservations.verticalbooking.com/premium/index.html?id_albergo=24785&dc=2267&lingua_int=usa&id_stile=18847"
          >
            Book Now
          </a> */}
        </div>
      </Slide>
      <Slide direction="down" in={isOpen}>
        {children}
      </Slide>
    </React.Fragment>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

const NavBar = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { history } = props

  return (
    <React.Fragment>
      <div className="hc-mobile-nav">
        <span
          className="hc-bars"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {isOpen ? (
            <span className="fa fa-times fa-3x"></span>
          ) : (
            <span className="fa fa-bars fa-3x"></span>
          )}
        </span>
        {!!props.banner ? (
          <div className="hc-nav-banner-mobile fs-description fam-lora">
            {props.banner}
          </div>
        ) : null}
        <div className="hc-mobile-nav-container">
          <ul className={`hc-navbar-list-mobile ${isOpen ? 'active' : ''}`}>
            <li className="hc-navbar-list--item hc-nav-logo">
              <a
                onClick={() => {
                  setIsOpen(false)
                  history.push({
                    pathname: '/',
                  })
                }}
              >
                <Image
                  className=" hc-navbar-logo"
                  src={props.nav.LogoWhite.url}
                  alt={props.nav.LogoWhite.alternativeText}
                />
              </a>
            </li>
            <li className="hc-navbar-list--item">
              <a
                onClick={() => {
                  setIsOpen(false)
                  history.push({
                    pathname: '/rooms',
                  })
                }}
              >
                Rooms
              </a>
            </li>
            {/* <li className="hc-navbar-list--item">
              <a
                onClick={() => {
                  setIsOpen(false)
                  history.push({
                    pathname: '/dining',
                  })
                }}
              >
                Dining
              </a>
            </li> */}
            <li className="hc-navbar-list--item">
              <a
                onClick={() => {
                  setIsOpen(false)
                  history.push({
                    pathname: '/downtown',
                  })
                }}
              >
                Downtown
              </a>
            </li>
            {/* <li className="hc-navbar-list--item">
				<a onClick={() => {
                setIsOpen(false);
                history.push({
                  pathname: '/weddings'
                })
              }}>Events</a>
            </li> */}
            <li className="hc-navbar-list--item">
              <a
                onClick={() => {
                  setIsOpen(false)
                  history.push({
                    pathname: '/about',
                  })
                }}
              >
                Hotel
              </a>
            </li>
          </ul>
        </div>
      </div>

      <HideOnScroll {...props}>
        <AppBar color="transparent" className="hc-navbar">
          <Toolbar className="hc-navbar--container">
            <Container maxWidth="lg" fixed>
              <ul className="hc-navbar-list">
                <li className="hc-navbar-list--item">
                  <Link to="/rooms">Rooms</Link>
                </li>
                {/* <li className="hc-navbar-list--item">
                  <Link to="/dining">Dining</Link>
                </li> */}

                <li className="hc-navbar-list--item hc-nav-logo">
                  <Link to="/">
                    <Image
                      className=" hc-navbar-logo"
                      src={props.nav.Logo.url}
                      alt={props.nav.Logo.alternativeText}
                    />
                  </Link>
                </li>
                <li className="hc-navbar-list--item">
                  <Link to="/downtown">Downtown</Link>
                </li>
                {/* <li className="hc-navbar-list--item">
                  <Link to="/weddings">Events</Link>
                </li> */}
                <li className="hc-navbar-list--item">
                  <Link to="/about">Hotel</Link>
                </li>
              </ul>
            </Container>
          </Toolbar>
          {!!props.banner ? (
            <div className="hc-nav-banner fs-description fam-lora">
              {props.banner}
            </div>
          ) : null}
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  )
}

export default withRouter(NavBar)
