import React from 'react'
import ReactDOM from 'react-dom'
import './styles/tailwind_output.css'
import './styles/reset.css'
import './styles/global.css'
import App from './App'
import { Router } from 'react-router-dom'
import ReactGA from 'react-ga'
//import { FavouriteProvider } from 'context/favourite';
//import AmpEmailAPI from 'api/AmpEmailAPI';
import * as serviceWorker from './serviceWorker'
import history from './utils/history.js'

const trackingId = 'UA-218766190-1'
ReactGA.initialize(trackingId)

ReactGA.ga('send', 'pageview', window.location.pathname)
//AmpEmailAPI.trackPage(window.location.pathname.replace("/", ""));

history.listen((location) => {
  //AmpEmailAPI.trackPage(location.pathname.replace("/", ""));
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

ReactDOM.render(
  <Router history={history}>
    {/* <FavouriteProvider> */}
    <App />
    {/* </FavouriteProvider> */}
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
