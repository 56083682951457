import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, withRouter,Redirect } from 'react-router-dom';
import Loader from './component/Loader';
import Layout from './component/Layout';
import { sizes } from './component/FixedAspectRatioImage';
import GoogleApiWrapper from './component/PointsOfInterest';
import { convertNullToUndefined } from './helpers';
import Contact from './container/Contact';
import EventContactForm from './container/Contact/EventContactForm';


const HomePage = React.lazy(() => import('./container/Home'));
const RoomPage = React.lazy(() => import('./container/Rooms'));
const DiningPage = React.lazy(() => import('./container/Dining'));
const WeddingPage = React.lazy(() => import('./container/Wedding'));

function App({ history }) {
  const [data, setPageData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/all`)
      .then(response => response.json())
      .then(data => {
        setPageData(convertNullToUndefined(data));
      }).catch(error => {
        // @todo handle error
        console.log(error);
        console.log('Something went wrong');
      });

    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  if (!data) {
    return <Loader></Loader>
  }

const  updateCanonicalTag= (tag)=> {
    var links = document.getElementsByTagName('link');
    for (var x = 0; x < links.length; x++) {
      if (links[x].getAttribute('rel') === 'canonical') {
        links[x].setAttribute('href', 'http://www.historichotelchester.com' + tag);
      }
    }
  };


  const { Navigation,Home, Rooms, Dining,Weddings,Downtown,About } = data;
  return (
    <Suspense fallback={<Loader />}>
      <Layout nav={Navigation} banner={Home.EmergencyBanner} policyContent={Rooms}>
      <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <React.Fragment>
                  {updateCanonicalTag('')}
                  <HomePage content={Home}></HomePage>
                </React.Fragment>
              )}
            />
            <Route
              path="/rooms/:selectedRoom?"
              render={props => (
                <div>
                  {updateCanonicalTag('/rooms')}
                  <RoomPage content={Rooms} selectedRoom={props.match.params.selectedRoom} />
                </div>
              )}
            />
            <Route
              path="/weddings"
              render={() => (
                <div>
                  {updateCanonicalTag('/wedding')}
                  <WeddingPage content={Weddings} />
                </div>
              )}
            />
            <Route
              path="/contact"
              render={() => (
                <div>
                  {updateCanonicalTag('/contact')}
                  <Contact />
                </div>
              )}
            />
            <Route
              path="/event-contact"
              render={() => (
                <div>
                  {updateCanonicalTag('/event-contact')}
                  <EventContactForm />
                </div>
              )}
            />
            {/* <Route
              path="/dining"
              render={() => (
                <React.Fragment>
                  {updateCanonicalTag('/dining')}
                  <DiningPage content={Dining} />
                </React.Fragment>
              )}
            /> */}

            <Route
              exact
              path="/about"
              render={() => (
                <React.Fragment>
                  {updateCanonicalTag('')}
                  <HomePage content={About} location={'about'} insetImageSize={sizes.SIZE_4_3}></HomePage>
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/downtown"
              render={() => (
                <React.Fragment>
                  {updateCanonicalTag('/downtown')}
                  <HomePage content={Downtown} location={'downtown'}></HomePage>
                  <div className="googleMapWrapper">
                    <GoogleApiWrapper />
                  </div>
                </React.Fragment>
              )}
            />
            <Route
              path="*" render={() => {
                return <Redirect to="/" ></Redirect>
              }}
            ></Route>
          </Switch>
        </Layout>
    </Suspense>
  );
}

export default withRouter(App);
