import React, { Component } from 'react';
import './index.css';
import ContactForm from './ContactForm.js';

class Contact extends Component {

    render() {
        return (
            <div className="Contact">
                <ContactForm />
            </div>
        );
    }
}

export default Contact;

