import React, { Component } from "react";
import './style.css';
class MapPin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attrD: "M100,100 v-100 a100,100 0 0,1 0,0 z",
        }
    }
    doWipe() {
        if (this.props.boxInfo.wipe === false) {
            this.wipe();
            this.props.boxInfo.wipe = true;
        }
    }

    wipe(circleId) {
        var drawCircleArc = (angle) => {
            var endAngleDeg = angle - 90;
            var endAngleRad = (endAngleDeg * Math.PI) / 180;
            var largeArcFlag = (angle < 180 ? '0' : '1');

            var endX = Math.cos(endAngleRad) * 100;
            var endY = 100 + (Math.sin(endAngleRad) * 100);

            var data = 'M100,100 v-100 a100,100 0 ' + largeArcFlag + ',1 ' +
                endX + ',' + endY + ' z';
            var finaldata = 'M 100 100 a 50 50 0 1 0 0.00001 0'
            this.setState({ "attrD": data });
        }

        // Code for running the animation

        //var arcAngle = 0;        // Starts at 0, ends at 360
        var arcAngleBy = 10;     // Degrees per frame
        var arcAngleDelay = 16.64;  // Duration of each frame in msec

        var updateCircleWipe = (arcAngle) => {
            arcAngle += arcAngleBy;

            drawCircleArc(arcAngle);

            if (arcAngle < 360) {
                setTimeout(function () { updateCircleWipe(arcAngle); }, arcAngleDelay);
            } else {
                this.setState({ "attrD": 'M0,100a100,100 0 1,0 200,0a100,100 0 1,0 -200,0' });
            }
        }

        setTimeout(function () { updateCircleWipe(0); }, arcAngleDelay);
    }


    importAll(r) {
        var values = {}
        r.keys().map((x) => {
            values[x] = r(x);
        });
        return values;
    }

    images = this.importAll(require.context('../../../assets/map-images', false, /\.(png|jpe?g|svg)$/));

    render() {
        if (this.props.boxInfo.showBox === true) {
            this.doWipe();
            return (
                <div className="red-box hide-on-mobile">
                    <h1>{this.props.boxInfo.data.Name}</h1>
                    <p>{this.props.boxInfo.data.Description}</p>
                    <br />
                    <p className="fs-description">
                        <a href={this.props.boxInfo.data.Url}>Website</a>
                    </p>
                    <br />
                    <p>
                        <a href={"tel:" + this.props.boxInfo.data.Phone} >{this.props.boxInfo.data.Phone}</a>
                    </p>
                    <svg>
                        <defs>
                            <clipPath id="clip-path-0">
                                <path d={this.state.attrD} id="circle-wipe-1" fill="#999" strokeWidth="0" />
                            </clipPath>
                        </defs>
                        <rect x="0" y="0" width="800" height="800" />
                        <image clipPath="url(#clip-path-0)" xlinkHref={this.images["./" + this.props.boxInfo.data.Image]}
                            x="0" y="0" height="200px" width="200px" />
                    </svg>
                    <div className="arrow-down"></div>
                </div>
            )
        }
        else {
            return (<div></div>)
        }
    }
}

export default MapPin