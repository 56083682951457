import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import './style.css';
import * as mapData from './map-data.json';
import MapPin from './MapPin';

const HCPin = { lat: 33.4640791, lng: -88.8141159 };
export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boxInfo: {
                data:null,
                showBox: false,
                wipe:false
            },
            mapPin: null 
        }
        //this.expandGroups = this.expandGroups.bind(this);
    }

    expandGroups(idx, status) {
        var div = document.querySelector('div[data-map-menu-index="' + idx + '"]');
        if (status === true) {
            div.classList.remove("submenu");
        } else {
            div.classList.add("submenu");
            this.setState(
                {
                    boxInfo: {
                        data: null,
                        showBox: false,
                        wipe:false
                    },
                    mapPin :{ lat: 33.4640791, lng: -88.8141159 }
                });
        }
    }

    setBox(groupIdx, itemIdx) {
        var selectedData =mapData.data[groupIdx].Data[itemIdx] 
        this.setState(
            {
                boxInfo: {
                    data: selectedData,
                    showBox: true,
                    wipe:false
                },
                mapPin:{lat:selectedData.Latitude,lng:selectedData.Longitude}
            });

    }

    render() {
        const { mapPin } = this.state;

        const style = {
            width: '100%',
            height: '100%',
        }

        return (
            <div className="map-container">
                <Map google={this.props.google} style={style}
                    initialCenter={{ lat: 33.4640791, lng: -88.8141159 }} center={this.state.mapPin}
                    zoom={16}>
                    <Marker
                        name={'Hotel Chester'}
                        label={'HC'}
                        position={HCPin}></Marker>
                    { mapPin && <Marker
                        position={mapPin}></Marker> }
                </Map>
                <div className="map-menu hide-on-mobile">
                    <div className="fs-h2">Neighborhood</div>
                    <div className="fs-h3">Explore the neighborhood with this custom guide by the hotel team</div>
                    <div className="fs-h3">Click categories to view</div>
                    <ul>
                        {
                            mapData.data.map((row, index) => (
                                <li className="fs-h3 poi-link" onClick={(e) => this.expandGroups(index, true, e)} key={"g-" + index}>{row.Group}</li>
                            ))
                        }
                    </ul>
                </div>
                <div>
                    {
                        mapData.data.map((row, index) => (
                            <div className="map-menu submenu" key={"gn-" + index} data-map-menu-index={index}>
                                <span className="close-submenu" onClick={(e) => this.expandGroups(index, false, e)}>X</span>
                                <div className="fs-h2">{row.Group}</div>
                                <div className="fs-h3">Explore the neighborhood with this custom guide by the hotel team</div>
                                <ul>
                                    {
                                        row.Data.map((dRow, dIndex) => (
                                            <li className="fs-h3 poi-link" key={"gd-" + dIndex} onClick={(e) => this.setBox(index, dIndex, e)}>{dRow.Name}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))
                    }
                </div>
                <div>

                    <MapPin boxInfo={this.state.boxInfo} />
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBYY2YGYDWs9tGCLLOJnUyXfEo_0ctf9I4'
})(MapContainer)
