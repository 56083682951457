import React, { Component } from 'react';

export function mapImageUrl(url, optimized=true) {
    if (optimized) {
        if (optimized === true) {
            url = `/optimized${url}?w=${window.innerWidth}`;
        }
        else if (!optimized.mode) {
            if (optimized.width) {
                url = `/optimized${url}?w=${optimized.width}`;
            }
            else if (optimized.height) {
                url = `/optimized${url}?h=${optimized.height}`;
            }
        }
        else {
            if (optimized.size) {
                url = `/optimized${url}?h=${optimized.size}&w=${optimized.size}&m=${optimized.mode}`;
            }
            else {
                url = `/optimized${url}?h=${optimized.height}&w=${optimized.width}&m=${optimized.mode}`;
            }
        }

        if (optimized !== true && 'quality' in optimized) {
            url += `&q=${optimized.quality}`;
        }
    }
    return mapUrl(url);
}


export function mapUrl(url) {
    return url;// ? 'http://localhost:53412' + url : null;
}

export class DisplayHtml extends Component {
    render() {
        return <span dangerouslySetInnerHTML={{__html: this.props.html}} />;
    }
}



export function convertNullToUndefined(obj) {
    if (obj === null) return undefined;
    if (typeof obj === 'object') {
        for (let key in obj) {
            obj[key] = convertNullToUndefined(obj[key]);
        }
    }
    return obj;
}

export function setPageTitle(title){
	document.title = title;
}