import React, { useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import defaultFallback from '../../assets/placeholder.png';
import { divImageObserver } from '../../utils/imageDelay';

export const sizes = {
    SIZE_21_9: '42.85%',
    SIZE_16_9: '56.25%',
    SIZE_3_2: '66.66%',
    SIZE_4_3: '75%',
    SIZE_4_5: '125%',
    SIZE_2_3: '150%',
    SIZE_1_1: '100%',
}

const FixedAspectRatioImage = ({ src, fallbackSrc, size, optimize, verticalAlign, children, ...restProps }) => {
    let ref = useRef(null);
    useEffect(() => {
        divImageObserver.observe(ref.current);
    }, []);

    let backgroundPosition;
    if (verticalAlign === 'top') {
        backgroundPosition = 'center top';
    } else if (verticalAlign === 'bottom') {
        backgroundPosition = 'center bottom';
    } else {
        backgroundPosition = 'center center';
    }

    src = `${process.env.REACT_APP_API_DOMAIN}${src}`;
    
    if (src === '') {
        src = fallbackSrc;
    }

    const bgImg = {
        paddingTop: size || '100%',
        // backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition,
        backgroundAttachment: 'scroll',
        position: 'relative',
        boxSizing: 'border-box',
        width: '100%'
    };

    return <div ref={ref} style={bgImg} data-src={src} {...restProps}>
        <Box position="absolute" top="0" left="0" bottom="0" right="0">
            {children}
        </Box>
    </div>;
};

FixedAspectRatioImage.propTypes = {
    src: PropTypes.string,
    fallbackSrc: PropTypes.string,
    useImgTag: PropTypes.bool,
    optimize: PropTypes.bool,
};

FixedAspectRatioImage.defaultProps = {
    fallbackSrc: defaultFallback,
    optimize: false,
};

export default FixedAspectRatioImage;
