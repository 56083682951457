import React, {useState,useEffect} from 'react';
import { Dialog, Slide } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import './ContactForm.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const captchaKey = "6LeqprYaAAAAAA-WgMLin_nPvOFuv7Y5IapCdjxn";

const ContactForm = () => {
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
       
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
       
          if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${captchaKey}`, function () {
        });
      }, []);
    const [ recaptcha, setRecaptcha ] = useState(null);
    const [success, setSuccess] = useState(null);
    const { register, handleSubmit, errors, reset } = useForm();

    const onRecaptchaChange = (value) => {
        setRecaptcha(value);
    }

    const onSubmit = (data,e) => {
        e.preventDefault();
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(captchaKey, { action: 'submit' }).then(token => {
            submitData(token,data);
          });
        });
      }
      const submitData = async (token,data) => {

        const json = await fetch('/api/contactus', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...data, "Recaptcha":token })
        }).then(response => response.json())
            .catch(err => setSuccess(false));
        const { success } = json;
        setSuccess(success);
        if (success) {
            reset();
        }
    };

    return (
        <div className="ContactFormHome">
            <div className="ContactFormContent">
                <h1>Contact Us</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group half-width">
                        <input ref={register({ required: true })} type="text" name="firstName" placeholder="First Name" />
                        {errors.eventType && <div className="error">First Name is required</div>}
                    </div>
                    <div className="form-group half-width">
                        <input ref={register({ required: true })} type="text" name="lastName" placeholder="Last Name" />
                        {errors.lastName && <div className="error">Last Name is required</div>}
                    </div>
                    <div className="form-group half-width">
                        <input ref={register({ required: true })} type="text" name="email" placeholder="Email" />
                        {errors.email && <div className="error">Email is required</div>}
                    </div>
                    <div className="form-group half-width">
                        <input ref={register({ required: true })} type="text" name="mobile" placeholder="Phone Number" />
                        {errors.mobile && <div className="error">Phone Number is required</div>}
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-black btn-block">Submit</button>
                    </div>
                </form>
            </div>
            <Dialog
                fullWidth
                maxWidth="md"
                open={success !== null}
                onClose={() => {
                    setSuccess(null);
                }}
                TransitionComponent={Transition}
                keepMounted
                scroll="body"
            >
                <div className="contact-form__modal">
                    {success ?
                        <div className="fs-h3">
                            Thank you and its great to e-meet you. We’ll be in touch shortly.
                        </div> :
                        <div className="fs-h3">
                            Oops! something’s wrong. Try calling the hotel at the number below.
                        </div>
                    }
                    <button
                        className="btn btn-black btn-block"
                        onClick={() => {
                            setSuccess(null);
                        }}>Close</button>
                </div>
            </Dialog>
        </div>
    );
}



export default ContactForm;

