import 'intersection-observer';

const options = {
    threshold: 0,
    rootMargin: "0px 0px 400px 0px"
}

function handleLoadDivImage(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            const div = entry.target;
            const src = div.getAttribute('data-src');
            div.style.backgroundImage = `url(${src})`
            observer.unobserve(div);
        }
    })
}
    
export const divImageObserver = new IntersectionObserver(handleLoadDivImage, options);