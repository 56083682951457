import React, { useState } from 'react';
import { Box, Dialog, Grid, Slide } from '@material-ui/core';

import Image from '../Image';
import FooterBrick from "../../assets/footer_brick_opt.jpg";
import TripAdvisor from "../../assets/tripadvisor_white.svg";
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import BespokeLogo from "../../assets/bespoke_footer_icon.png";
import './style.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Footer = ({ policyContent }) => {
    const [policy, setPolicy] = useState(null);
    return <div className="footer-container" style={{ backgroundImage: `url(${FooterBrick})` }}>
        <Grid item xs={12} md={4}>
            <div className="footer-section-1">
                <h3>
                    101 North Jackson St
                </h3>
                <h3>
                    Starkville, MS 39759
                </h3>
                <h3 className="mt-3">
                    Toll Free: 866.325.5005
                </h3>
                <h3>
                    Phone: 662.323.5005
                </h3>
                <h3>
                    Fax: 662.320.7006
                </h3>
            </div>

        </Grid>
        <Grid item xs={12} md={4}>
            <div className="footer-section-2">
                <span href="#" className="fs-h3" onClick={() => {
                    setPolicy(policyContent.CovidProtocols);
                }}>
                    COVID-19 Protocols
                </span>
                <span href="#" className="fs-h3" onClick={() => {
                    setPolicy(policyContent.OurPolicy);
                }}>
                    Our Policies
                </span>
                <span href="#" className="fs-h3" onClick={() => {
                    setPolicy(policyContent.Cancellations);
                }}>
                    Cancellation
                </span>
                <span className="fs-h3" href="#" onClick={() => {
                    setPolicy(policyContent.Cancellations);
                }}>
                    Privacy Policy
                </span>
                <div className="footer-social-icons-container">
                    <img className="social-icon" src={TripAdvisor} alt="Trip Advisor Logo" />
                    <InstagramIcon className="social-icon"></InstagramIcon>
                    <FacebookIcon className="social-icon"></FacebookIcon>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={4}>
            <div className="footer-section-3">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Image className="footer-bespoke-logo" src={BespokeLogo} isExternal={false}></Image>
                </Box>
            </div>
        </Grid>
        <Dialog
            fullWidth
            maxWidth="sm"
            open={!!policy}
            onClose={() => {
                setPolicy(null);
            }}
            TransitionComponent={Transition}
            scroll="paper">
            <Box padding="2rem">
                <div className="fam-lora fs-h3" dangerouslySetInnerHTML={{ __html: policy }}></div>
            </Box>
        </Dialog>
    </div>;
};

export default Footer;
